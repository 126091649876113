import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Footer.css';
import { Link } from 'react-router-dom';
import useApiRequest from './ApiComponent'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const { response, error } = useApiRequest('GET', '/api/get_Category');
    const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Magazinarticles');
    console.log("response footer ", response)
    const navigate = useNavigate();
    const [trandingData, setTrendingData] = useState()
    useEffect(() => {
        if (response1) {
            const data = response1['magazinarticles List'];
            if (data) {
                const newTrendingArticles = data.filter(
                    (article) => article.active && article.tranding
                );

                const sortedTrendingData = [...(newTrendingArticles || [])].sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                console.log("sortedTrendingData ", sortedTrendingData)
                setTrendingData(sortedTrendingData); // Store the trending data
                // setVisibleData(sortedTrendingData.slice(0, 10)); // Display the first 10 articles initially
            }
        }
    }, [response1]);
    const handleReadViewDetails = (article) => {
        navigate('/ArticleDetails', { state: { article } });
    };
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h5>QUICK LINKS</h5>
                        <ul className="">
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h5>TOP CATEGORIES</h5>
                        <ul>
                            {response?.['Category List'] &&
                                response['Category List']
                                    .filter(category => category.active) // Filter active categories
                                    .slice(0, 7) // Show only the first 6 categories
                                    .map(category => (
                                        <React.Fragment key={category.id}>
                                            <li>
                                                <Link
                                                    to={`/${category.category.replace(/\s+/g, '')}`}
                                                    className='Link-text'
                                                >
                                                    {category.category}
                                                </Link>
                                            </li>
                                        </React.Fragment>
                                    ))}

                        </ul>

                    </div>
                    <div className="col-md-3">
                        <h5>LATEST STORIES</h5>
                        <ul >
                            {trandingData?.slice(0, 5).map((article) => (
                                <li key={article.id}>
                                    <a href="" onClick={() => handleReadViewDetails(article)}>{article.heading}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <ul>
                            {trandingData?.slice(5, 9).map((article) => (
                                <li key={article.id}>
                                    <a href="" onClick={() => handleReadViewDetails(article)}>
                                        {article.heading}
                                    </a>
                                </li>
                            ))}
                        </ul>

                        {/* <ul>
                            <li><a href="#">Experts Call For A Fresh Approach In Independence Day Campaigns</a></li>
                            <li><a href="#">Roommates Or LFFs? Tide’s Superior Clean Bran Brings Gen Z Closer</a></li>
                            <li><a href="#">EssenceMediacom Wins The Integrated Media Mandate For Bambino Pasta Foods Industries Private Ltd.</a></li>
                        </ul> */}
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© {new Date().getFullYear()} V-Finder</p>
                    {/* <div className="social-icons">
        <a href="#"><i className="bi bi-facebook"></i></a>
        <a href="#"><i className="bi bi-instagram"></i></a>
        <a href="#"><i className="bi bi-twitter"></i></a>
    </div> */}
                </div>

            </div>
        </footer>
    );
};

export default Footer;
