import React from 'react';
import DOMPurify from 'dompurify';
import '../assets/css/ExclusiveArticles.css';
import { Link, useNavigate } from 'react-router-dom';
import useApiRequest from './ApiComponent';

const ExclusiveArticles = () => {
    const { response, error } = useApiRequest('GET', '/api/get_Magazinarticles');
    const url = localStorage.getItem('url');
    const articles = response?.['magazinarticles List'];
    const navigate = useNavigate();

    console.log("response", response);
    const handledetialsPage = (article) => {
        navigate('/ArticleDetails', { state: { article } });
      }
    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length <= wordLimit) {
            return text;
        }
        return words.slice(0, wordLimit).join(' ') + '...';
    };

    if (!articles) {
            return <div class="loader"></div>
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 text-Ex">
                    <h1 className="text-center">EXCLUSIVE ARTICLES</h1>
                </div>
            </div>
            <div className="row">
                <div className="articles">
                    <div className="f_div"><span className='arrowicon'>→</span></div>
                    {articles.map((article, index) => (
                        article.status === "Exclusive Article" && article.active==1&& (
                            <div key={index} className="article-title" onClick={() => { handledetialsPage(article) }}>
                                <span className="title">{article.heading}</span>
                                <div className="article-content">
                                    <div className="card-Ex border-0">
                                        <img src={`${url}/public/image/${article.image}`} className="card-img-top" alt={article.heading} />
                                        <div className="card-body-ex text-left">
                                            <h5 className="card-title-ex">{article.heading}</h5>
                                            <p className="card-text-ex">{truncateText(DOMPurify.sanitize(article.shortdes), 20)}</p>
                                            <div className="Read-More-btn">
                                                <a  className="read-more-button" onClick={() => { handledetialsPage(article) }}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ExclusiveArticles;
