import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import 'bootstrap/dist/css/bootstrap.min.css';
import useApiRequest from './ApiComponent';
import { useNavigate } from 'react-router-dom';
import '../assets/css/MoreArticles.css';

const MoreArticles = () => {
  const { response: response1 } = useApiRequest('GET', '/api/get_Magazinarticles');
  const navigate = useNavigate();
  const url = localStorage.getItem('url');
  const Dataview = response1?.['magazinarticles List'];

  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 8;

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  const handledetialsPage = (article) => {
    navigate('/ArticleDetails', { state: { article } });
  };

  if (!Dataview) {
    return <div class="loader"></div>
  }

  const filteredArticles = Dataview.filter(
    article => article.status === "Article" && article.active && article.tranding === null && article.breaking === null
  );

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className='articles-container'>
        <div className="container">
          <div className="more-articles">
            <h2>MORE ARTICLES</h2>
          </div>
          <div className="row">
            {currentArticles.length > 0 ? (
              currentArticles.map((article, index) => (
                <div className="col-md-3" key={index} onClick={() => { handledetialsPage(article) }} style={{ cursor: 'pointer' }}>
                  <div className="card article-card">
                    <div className="article-image">
                      <img src={`${url}/public/image/${article.image}`} alt="Article Image" />
                    </div>
                    <div className="card-body article-content">
                      <h5 className="card-title-Art">{article.heading}</h5>
                      <p className="card-text-Art">{truncateText(DOMPurify.sanitize(article.shortdes), 20)}</p>
                      <div className="d-flex justify-content-between date-Art">
                        <div className="article-date" style={{ backgroundColor: '#fff' }}>{article.date}</div>
                        <div className="article-eye" style={{ backgroundColor: '#fff' }}><i className="fa fa-eye"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No articles found.</div>
            )}
          </div>

          {/* Updated Pagination controls with enhanced design */}
          {totalPages > 1 && (
            <div className="container">
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center d-flex flex-wrap">
                  {/* Previous Page Button */}
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={handlePrevPage} disabled={currentPage === 1}>
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </li>

                  {/* Page Numbers */}
                  {[...Array(totalPages)].map((_, pageIndex) => (
                    <li key={pageIndex} className={`page-item ${currentPage === pageIndex + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => handlePageChange(pageIndex + 1)}>
                        {pageIndex + 1}
                      </button>
                    </li>
                  ))}

                  {/* Next Page Button */}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={handleNextPage} disabled={currentPage === totalPages}>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default MoreArticles;
