import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import ArticleDetails from './pages/ArticleDetails';
import Contactus from './pages/Contactus';
import CoverStory from './pages/CoverStory';
import About from './pages/About';
import useApiRequest from './components/ApiComponent';
import FiveOhFiveComponent from './components/FiveOhFiveComponent';
import FormComponent from './components/FormComponent'; // Import the form component
import './App.css'
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const [isFormVisible, setIsFormVisible] = useState(true); // Show form on load
  const { response: categories, error } = useApiRequest('GET', '/api/get_Category');

  const hideForm = () => setIsFormVisible(false);

  if (error) {
    return <FiveOhFiveComponent />;
  }

  return (
    <Router>
      <ScrollToTop />
      {isFormVisible && <FormComponent onClose={hideForm} />}
      <Header categories={categories?.['Category List'] || []} />
      
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/ArticleDetails" element={<ArticleDetails />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contactus />} />
        {categories?.['Category List']?.map(category => (
          <Route 
            key={category.id} 
            path={`/${category.category.replace(/\s+/g, '')}`} 
            element={<CoverStory PageName={category.category} />} 
          />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
