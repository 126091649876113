import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import useApiRequest from './ApiComponent'; // Import your custom hook for API requests
import Footer from './Footer';
import SubscribeVart from './SubscribeVart';
import Addvertisement from './Advertisement';
import { FaInstagram, FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa';

const ArticlePage = ({ details }) => {
    const [trendingData, setTrendingData] = useState([]);
    const [visibleData, setVisibleData] = useState([]); // Data to display at any given time
    const [currentIndex, setCurrentIndex] = useState(0); // Tracks the index of the data to show
    const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Magazinarticles');

    useEffect(() => {
        if (response1) {
            const data = response1['magazinarticles List'];
            if (data) {
                const newTrendingArticles = data.filter(
                    (article) => article.active && article.tranding
                );

                const sortedTrendingData = [...(newTrendingArticles || [])].sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                setTrendingData(sortedTrendingData); // Store the trending data
                setVisibleData(sortedTrendingData.slice(0, 10)); // Display the first 10 articles initially
            }
        }
    }, [response1]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => {
                const nextIndex = (prevIndex + 5) % trendingData.length;

                // Check if nextIndex exceeds the available data
                if (nextIndex + 5 >= trendingData.length) {
                    setVisibleData(trendingData.slice(0, 5)); // Reset to start from 0
                    return 0; // Start again from the 0 index
                } else {
                    setVisibleData(trendingData.slice(nextIndex, nextIndex + 5));
                    return nextIndex;
                }
            });
        }, 4000);

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [trendingData]);


    const handleReadViewDetails = (article) => {
        navigate('/ArticleDetails', { state: { article } });
    };

    const url = localStorage.getItem('url');
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [showFullContent, setShowFullContent] = useState(false); // State for "Show More"
    const shareUrl = window.location.href; // Current page URL to share
    const shareText = details.heading; // Text to share
    // const category = details.category || '';
    // const heading = details.heading || '';
    // const shortDec = details.shortdes || ';'
    const image = `${url}/public/image/${details.image}` || '';
    // const authorImage = details.authorimage || '';
    // const authorName = details.authorname || '';
    // console.log("details ",details)
    const { category, heading, shortdes, authorimage, authorname, instagramlink, twitterlink, linkdinlink, facebooklink } = details;
    const splitIntoChunks = (text, chunkSize) => {
        if (!text) return [];
        const words = text.split(' ');
        const chunks = [];
        for (let i = 0; i < words.length; i += chunkSize) {
            chunks.push(words.slice(i, i + chunkSize).join(' '));
        }
        return chunks;
    };

    const getYouTubeIDs = (urls) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^"&?\/\s]{11})/;
        return urls.split(',').map(url => {
            const match = url.trim().match(regex);
            return match ? match[1] : '';
        }).filter(id => id !== '');
    };

    const bodyChunks = splitIntoChunks(details.body, 50); // Split the article into chunks of 50 words
    const videoURLs = details.videofile || '';
    const videoIDs = getYouTubeIDs(videoURLs);
    const contentSections = [];
    const videoInsertionPoints = [2, 5]; // Positions where videos will be inserted

    // Add initial content chunks
    for (let i = 0; i < bodyChunks.length; i++) {
        if (i === 0 && image) {
            contentSections.push(
                <div key="image" style={{ marginTop: '20px' }}>
                    <img src={image} alt="Article Image" style={{ width: '100%', height: 'auto' }} />
                </div>
            );
        }
        contentSections.push(<div key={`content-${i}`} dangerouslySetInnerHTML={{ __html: bodyChunks[i] }} />);
        if (videoInsertionPoints.includes(i)) {
            const videoID = videoIDs.shift();
            if (videoID) {
                contentSections.push(
                    <div key={`video-${i}`} style={{ marginTop: '20px' }}>
                        <iframe
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoID}`}
                            title={`YouTube video ${i}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                );
            }
        }
    }





    return (
        <>
            <div className="container article-container">
                <Addvertisement />
                <div className="row">
                    <div className="col-md-8 mt-2">
                        <div className="article-content mt-4">
                            <div className="article-body">
                                <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{category}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }} className='detailshead'>{heading}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }} className=''>{shortdes}</div>
                                </div>
                                {authorimage && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <img src={`${url}/public/image/${authorimage}`} alt="Author" style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                                        <div>{authorname}</div>
                                        <div style={{ marginLeft: '10px', display: 'flex', gap: '10px' }}>
                                            {instagramlink && (
                                                <a href={instagramlink} target="_blank" rel="noopener noreferrer">
                                                    <FaInstagram style={{ color: '#E4405F', fontSize: '18px' }} />
                                                </a>
                                            )}
                                            {twitterlink && (
                                                <a href={twitterlink} target="_blank" rel="noopener noreferrer">
                                                    <FaTwitter style={{ color: '#1DA1F2', fontSize: '18px' }} />
                                                </a>
                                            )}
                                            {linkdinlink && (
                                                <a href={linkdinlink} target="_blank" rel="noopener noreferrer">
                                                    <FaLinkedin style={{ color: '#0077B5', fontSize: '18px' }} />
                                                </a>
                                            )}
                                            {facebooklink && (
                                                <a href={facebooklink} target="_blank" rel="noopener noreferrer">
                                                    <FaFacebook style={{ color: '#1877F2', fontSize: '18px' }} />
                                                </a>
                                            )}
                                        </div>
                                    </div>

                                )}
                                {showFullContent ? contentSections : contentSections.slice(0, 10)}
                                {!showFullContent && (
                                    <div className="text-center" style={{ paddingBottom: "25px" }}>
                                        <button className="More-Button" onClick={() => setShowFullContent(true)}>Read More...</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Sidebar */}
                    <div className="col-md-4 mt-3">
                        <div className="sidebar">
                            <div className='searchBox'>
                                <h5 className='search-text'>Search</h5>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        aria-label="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='Trending-container'>
                                <h4 className='Trending-text'>Trending</h4>
                                <hr />
                                <div className="trending-items-container">
                                    {visibleData && visibleData.map((item, index) => (
                                        <div key={item.id} style={{ backgroundColor: '#EEEEEE' }} onClick={() => handleReadViewDetails(item)}>
                                            <div className="trending-item">
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ backgroundColor: '#EEEEEE' }}>
                                                        <img
                                                            src={`${url}/public/image/${item.image}`}
                                                            alt="Trending Image"
                                                            className='search-box-img'
                                                        />
                                                    </div>
                                                    <div style={{
                                                        lineHeight: '17px',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        padding: '10px',
                                                        backgroundColor: '#EEEEEE'
                                                    }}>
                                                        <div style={{ backgroundColor: '#EEEEEE' }}>
                                                            <span style={{ backgroundColor: '#EEEEEE' }}>{item.heading || item.description}</span>
                                                        </div>
                                                        <div style={{
                                                            marginTop: '13%',
                                                            backgroundColor: '#EEEEEE'
                                                        }}>
                                                            <span style={{
                                                                fontSize: '10px',
                                                                color: '#676767', backgroundColor: '#EEEEEE'
                                                            }}>{item.shortdes}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <SubscribeVart />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArticlePage;
