import { useState, useEffect } from 'react';
import axios from 'axios';

const useApiRequest = (method, url, body) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const urlLink = "https://admin.vfindermagazine.com";
        localStorage.setItem("url",urlLink)
        const handleApiRequest = async () => {
            try {
                let res;
                if (method.toLowerCase() === 'get') {
                    res = await axios.get(urlLink + url);
                } else if (method.toLowerCase() === 'post') {
                    res = await axios.post(urlLink + url, body);
                } else {
                    throw new Error('Unsupported method');
                }
                setResponse(res.data);
            } catch (err) {
                setError(err.message);
            }
        };

        handleApiRequest();
    }, [method, url, body]);

    return { response, error };
};

export default useApiRequest;
