import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Cover.css'
import ArticleList from '../components/ArticleList';
import MoreArticles from '../components/MoreArticles';
import useApiRequest from '../components/ApiComponent';
import { Link, useNavigate } from 'react-router-dom';
import Subscribe from '../components/Subscribe';   
import Footer from '../components/Footer'; 

const CoverStory = ({ data, name }) => {
    const url = localStorage.getItem('url');
    const navigate = useNavigate();
    const handledetialsPage = (article) => {
        navigate('/ArticleDetails', { state: { article } });
      }
    return (
        <>
            {data
                .filter(article =>article.active && article.status === "Article" && name === article.category)
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date descending
                .slice(0, 1) // Get the latest article
                .map((article, index) => (
                    <div className="cover-story" key={index} onClick={()=>{handledetialsPage(article)}} style={{cursor:'pointer'}}>
                        <img src={`${url}/public/image/${article.image}`} alt="Cover Story" />
                        <div className="new-badge" >New</div>
                        <div className="overlay">
                            <h2 className='overlay-text'>{article.heading}</h2>
                            <p className='overlay-text-p'>{article.shortdes}</p>
                        </div>
                    </div>
                ))}

        </>
    );
};

const SideStory = ({ data, name }) => {
    const url = localStorage.getItem('url');
    const navigate = useNavigate();
    const handledetialsPage = (article) => {
        navigate('/ArticleDetails', { state: { article } });
      }

    return (
        <>
            {data
                .filter(article => article.active&& article.status === "Article" && name === article.category)
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date descending
                .slice(1, 3) // Skip the latest article and get the next two
                .map((article, index) => (
                    <div className="side-story mb-4" key={index} style={{cursor:'pointer'}} onClick={()=>{handledetialsPage(article)}}>
                        <img src={`${url}/public/image/${article.image}`} alt="Side Story" />
                        <div className="overlay">
                            <p className='overlay-text-side'>{article.shortdes}</p>
                        </div>
                    </div>
                ))}

        </>
    );
};

const CoverPage = ({ PageName }) => {
    const { response: response2, error: error2 } = useApiRequest('GET', '/api/get_Magazinarticles');
    const navigate = useNavigate();
    const Dataview = response2?.['magazinarticles List'];
    // const handleReadMore = (article) => {
    //     navigate('/ArticleDetails', { state: { article } });
    // };
    const coverimg = require('../assets/images/covermain.jpg');
    const coverimg2 = require('../assets/images/cover1.jpg');
    //console.log("PageName ",PageName)
    if (!Dataview) {
        return <div></div>;
    }
    return (
        <>
            <div style={{ marginTop: '140px' }}>

            </div>
            <div className="container" >
                <div className="row">
                    <div>
                        <h1 className='cover-head'>{PageName}</h1>
                    </div>
                    <div className="col-md-8">
                        <CoverStory data={Dataview} name={PageName} />
                    </div>
                    <div className="col-md-4">
                        <SideStory data={Dataview} name={PageName} />
                        {/* <SideStory
                            imageUrl={coverimg2}
                            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        /> */}
                    </div>
                </div>
            </div>
            <ArticleList categoryName={PageName} />
            <MoreArticles />
            <Subscribe />
            <Footer/>
        </>
    );
};

export default CoverPage;
